$(window).on('load', () => {

	var mobile = $(window).width() > 999 ? false : true;
	var banner = new Swiper('.banner .swiper-container',{
		slidesPerView:1,
		pagination:{
			el: '.banner .swiper-pagination',
			clickable: true
		}
	})

	var bannerBlog = new Swiper('.banner-blog .swiper-container',{
		slidesPerView:1,
		loop:true,
		autoplay:{
			delay:8000
		},
		pagination:{
			el: '.banner .swiper-pagination',
			clickable: true
		},
		navigation:{
			prevEl: ".banner-blog .swiper-button-prev",
			nextEl: ".banner-blog .swiper-button-next",
		}
	})

	var produto = new Swiper('.single-produtos .primary .swiper-container',{
		slidesPerView:1,
		pagination:{
			el: '.single-produtos .primary .swiper-pagination'
		},
		navigation:{
			prevEl: '.single-produtos .primary .swiper-button-prev',
			nextEl: '.single-produtos .primary .swiper-button-next',
		}
	})
	if(!mobile){
		var thumbs = new Swiper('.single-produtos .thumbs .swiper-container',{
			slidesPerView:4,
			spaceBetween: 18,
			preventClick: false,
			navigation:{
				prevEl: '.single-produtos .thumbs .swiper-button-prev',
				nextEl: '.single-produtos .thumbs .swiper-button-next',
			}
		})
	}

	var relacionados = new Swiper('.single-produtos .relacionados .swiper-container',{
		slidesPerView:2,
		spaceBetween: 4,
		pagination:{
			el: '.single-produtos .relacionados .swiper-pagination'
		},
		navigation:{
			prevEl: '.single-produtos .relacionados .swiper-button-prev',
			nextEl: '.single-produtos .relacionados .swiper-button-next',
		}
	})

	var mobile = $(window).width() > 992 ? false : true;

	$(".btn-toggle").on("click", function(){
		$("nav").addClass("active")
	})
	
	$(".subfooter .btn, .menu-footer .close").on('click',function(){
		$(".menu-footer").toggleClass("active")
		$("body").toggleClass('locked')
	})

	if(mobile){
		$("nav .menu-item-has-children").on('click', function(){
			$(this).find('.sub-menu:first').toggleClass('active')
			$("nav .close i").removeClass("fa-times")
			$("nav .close i").addClass("fa-angle-left")
		})

		$(".single-produtos .btn-title").on('click', function(){
			var parent = $(this).parent()
			var top = parent.position().top
			parent.toggleClass('active')
			$('body').toggleClass('locked')
		})
	}

	$("nav .close").on("click", function(){
		if($(".sub-menu.active").length > 0){
			$(".sub-menu").removeClass("active")
			$("nav .close i").addClass("fa-times")
			$("nav .close i").removeClass("fa-angle-left")
		}else{
			$("nav").removeClass("active")
		}
	})

	$(".app form").submit(function(){
		let inputs = $(this).serializeArray()
		window.localStorage.setItem("ferramenta", JSON.stringify({user:{name: inputs[0].value, email:inputs[1].value }}));
		location.href= window.BASE_URL + "/dimensione-o-seu-acoplamento"
		//location.href = "https://conteudo.antaresacoplamentos.com.br/nos-falamos-com-voce";
		return false
	})

	$(".ancora").on('click', 'a', function(){
		var target = $(this).attr('href')
		target = target.replace("#","")

		$('html, body').animate({
			scrollTop: $('.'+target).offset().top - 100
		}, 2000);

		return false
	})

	var pais = $(".page-template-page-distribuidores .filter select[name=pais] option:selected").val()
	if(pais){
		$(".page-template-page-distribuidores .filter select[name=estado]").removeAttr('disabled')
	}else{
		$(".page-template-page-distribuidores .filter select[name=estado]").attr('disabled','disabled')
	}

	$(".page-template-page-distribuidores .filter select").on('change', function(){
		var pais = $(".page-template-page-distribuidores .filter select[name=pais] option:selected").val()
		var estado = $(".page-template-page-distribuidores .filter select[name=estado] option:selected").val()

		if(pais == ""){
			$(".page-template-page-distribuidores .filter select[name=estado] option:selected").val('')
		}
		
		$(this).parent().parent().submit()
		
	})

	$(window).scroll(function(){
		if($(".single-produtos").length > 0){
			var caract = $(".produto-caracteristicas").offset().top - 100
			var tabelas = $(".produto-tabelas").offset().top - 100
			var downloads = $(".produto-downloads").offset().top - 100
			var app = $("#app").offset().top - 100

			$(".ancora a").removeClass()

			if($(this).scrollTop() >= (caract - 150) && $(this).scrollTop() <= (caract + $(".produto-caracteristicas").height()+100) ){
				$(".ancora a:eq(0)").addClass("active")
			}

			if($(this).scrollTop() >= tabelas && $(this).scrollTop() <= (tabelas + $(".produto-tabelas").height()+100) ){
				$(".ancora a:eq(1)").addClass("active")
			}

			if($(this).scrollTop() >= (downloads - 50) && $(this).scrollTop() <= (downloads + $(".produto-downloads").height()+100) ){
				$(".ancora a:eq(2)").addClass("active")
			}

			if($(this).scrollTop() >= (app - 50) && $(this).scrollTop() <= (app + $(".app").height()+180) ){
				$(".ancora a:eq(3)").addClass("active")
			}
		}
	})

	$('.distributor').on('click','.link-tel', function(){
		$(this).addClass('d-none').prev().removeClass('d-none')
	})

	$(".tabs .tabs-header").on('click','.tab-item', function(){
		$('.tabs .tab-item').removeClass('active')
		$(this).addClass('active')
		var target = $(this).data('target')

		$('.tabs .tabs-section').find('.'+target).addClass('active')
	})

	/*$(".btn-downloads").on("click", function(){
		$(".downloads-popup").toggleClass("active")
	})


	$(".downloads-popup .close").on("click", function(){
		$(".downloads-popup").toggleClass("active")
	})*/

	$(".search-mobile").on('click', function(){
		$(".search").toggleClass("fixed d-flex")
		$(this).find('.fas').toggleClass('fa-times')
	})

});
